import React, { FC } from "react";
import Head from "next/head";

interface HeaderProps {
  title: string;
  description: string;
}

const Header: FC<HeaderProps> = (props) => {
  return (
    <Head>
      <title> Admin | {props.title || undefined}</title>
      <meta name="description" content={props.description || undefined} />
      <meta name="robots" content="noindex"></meta>
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

export default Header;
