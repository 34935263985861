import type { NextPage } from "next";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import Header from "../common/components/utils/header";
import { data } from "autoprefixer";

const Home: NextPage = () => {
  const router = useRouter();

  useEffect(() => {
    const url = `${window.location.href}`;

    if (url.includes("logout=true")) {
      router.push("/auth/login");
    } else {
      fetch(`/api/auth/logout-link`, {
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => router.push(data.url));
    }
  }, []);

  return (
    <>
      <Header title="Home" description="Home" />
      <div className="h-screen flex justify-center items-center">
        <h1 className="text-4xl text-gray-900">
          Welcome to{" "}
          <span className="text-indigo-500 font-medium">Numarqe</span>
        </h1>
      </div>
    </>
  );
};

export default Home;
